import React from 'react'
import { graphql, navigate, withPrefix } from 'gatsby'
import { getUserLangKey } from 'ptz-i18n'

import SEO from '../components/seo'

export default ({ data }) => {
  if (typeof window !== 'undefined') {
    const { langs, defaultLangKey } = data.site.siteMetadata.languages
    const langKey = getUserLangKey(langs, defaultLangKey)

    navigate(withPrefix(`/${langKey}`))
  }
  return (
    <React.Fragment>
      <SEO title="Ari Dental Office" />
    </React.Fragment>
  )
}

export const pageQuery = graphql`
  query RedirectQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`
